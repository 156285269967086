import React from "react";
import {FormSection} from "../../store/models/form-service";
import FormSectionTitle from "./FormSectionTitle";
import {FormField} from "../../store/models/form-field";
import FormSectionField from "./FormSectionField";
import * as styles from "../../styles/components/form/section-grid.module.scss"

export type FormSectionGridItemProps = {
    section: FormSection,
    fields: FormField[]
};

const FormSectionGridItem = ({ section, fields }: FormSectionGridItemProps) => {
    return (
        <>
            <div>
                <FormSectionTitle>
                    {section.title}
                </FormSectionTitle>
            </div>
            <div className={styles.grid}>
                {fields.map((field: FormField) => <FormSectionField key={field.uuid} field={field} />)}
            </div>
        </>
    );
}

export default FormSectionGridItem;
