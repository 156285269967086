import React from "react";
import * as styles from '../../styles/components/form/form-header.module.scss'
import LinkText from "../LinkText";
import {TextStyles} from "../typography";
import StyledText from "../typography/StyledText";


type Props = {
  title: string | null,
  pathname: string | null,
  links: any[] | null,
}

const FormHeader = (props: Props) => {
  const {title, pathname, links} = props;
  const formattedPathName = pathname?.substring(pathname.lastIndexOf('/') + 1);

  return (
    <div className={styles.container}>
      <StyledText variant={TextStyles.h1} className={styles.title} tag="h1" >
        {title}
      </StyledText>
      <div className={styles.links}>
        {links && links.map((link: any, index: number) => (
         <div>
            <LinkText
                key={index}
                href={link.url?.link}
                textVariant={link.url?.link.includes(formattedPathName) ? TextStyles.h2 : TextStyles.h2Regular }
                className={link.url?.link.includes(formattedPathName) ? styles.selected : ''}
            >
                {link.label}
            </LinkText>
         </div>
        ))}
      </div>
    </div>
  )
}

export default FormHeader
