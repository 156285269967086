// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "form-header-module--container--496f1";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var headerLink = "form-header-module--headerLink--49217";
export var links = "form-header-module--links--3baa6";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var selected = "form-header-module--selected--584d1";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "form-header-module--title--3d3cf";