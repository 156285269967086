import React from "react";
import {FormSection, FormService} from "../../store/models/form-service";
import FormSectionGridItem from "./FormSectionGridItem";
import {FormField} from "../../store/models/form-field";

export type FormSectionGridProps = {
    service: FormService
};

const FormSectionGrid = (props: FormSectionGridProps) => {
    const fields: FormField[] = props.service.fields;
    const sections: FormSection[] = props.service.sections;
    return (
        <div>
            {sections.map(section => {
                const sectionFields: FormField[] = fields.filter(field => field.sectionUuid == section.uuid);
                return <FormSectionGridItem key={section.uuid} section={section} fields={sectionFields}/>;
            })}
        </div>
    );
}

export default FormSectionGrid;
