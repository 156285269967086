import * as React from "react"
import {ReactNode, useEffect} from "react"
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {useActions} from "../../store/hooks";
import * as styles from '../../styles/layout/form-layout.module.scss';
import {fetchFormService} from "../../store/actions/form-service.action";
import FormSectionGrid from "./FormSectionGrid";
import {FormService} from "../../store/models/form-service";
import Button from "../common/button/Button";
import StyledText, {TextStyles} from "../typography";
import {scrollToTield} from "../../helpers/scroll-to-tield";
import {scrollToTop} from "../../helpers/scroll-to-top";
import {SkeletonFormSection} from "../common/SkeletonFormSection";
import {FormStatus} from "../../store/types/form-service.types";

type Props = {
  children: ReactNode,
  formIcon?: string | null,
  formUuid: string,
  submitTitle: string
}

export const FormLayout = (props: Props) => {
    const loading = useSelector((state: RootState) => state.formService.loading);
    const status = useSelector((state: RootState) => state.formService.status);
    const formService: FormService | null = useSelector((state: RootState) => state.formService.service);
    const {submitFormInstance, fetchFormService} = useActions();
    const errors = useSelector((state: RootState) => state.formService.errors);
    const errorsLength = Object.values(errors).filter(item => item != null).length;
    const {formIcon} = props;


    useEffect(() => {
        fetchFormService(props.formUuid);
    }, [])

    useEffect(() => {
        for (const key in errors) {
            if (errors[key] !== null) {
                scrollToTield(key);
                break;
            }
        }
    }, [errorsLength]);

    useEffect(() => {
        if (status === FormStatus.FORM_STATUS_SUBMITTED) {
            scrollToTop();
        }
    }, [status]);

    return (
    <div className={styles.container}>
      <div
        className={styles.image}
        style={{backgroundImage: `url(${formIcon})`}}
      />

      {formService && status === FormStatus.FORM_STATUS_LOADED ? <FormSectionGrid service={formService}></FormSectionGrid> :
          <SkeletonFormSection sections={4} field={4}></SkeletonFormSection>
      }

      {status !== FormStatus.FORM_STATUS_SUBMITTED && (
          <div className={styles.buttonContainer}>
              <Button variant='contained' color='secondary' className={styles.button} onClick={submitFormInstance} disabled={loading}>
                  <StyledText variant={TextStyles.button} tag="span">
                      {props.submitTitle}
                  </StyledText>
              </Button>
          </div>
      )}

    </div>
  )
}
