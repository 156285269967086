import React from "react";
import * as styles from "../../styles/components/form/success-block.module.scss"
import {IconArrowDown, IconLogoStep4} from "../svg";
import StyledText, {TextStyles} from "../typography";
import {SocialButtonModel} from "../../store/models/cta";
import Button from "../common/button/Button";
import {SocialButtonList} from "../common/button/SocialButtonList";

type FormSuccessBlock = {
    title: string,
    subtitle: string,
    socialTitle: string,
    email: string,
    socials: SocialButtonModel[] | null
}

const FormSuccessBlock = (props: FormSuccessBlock) => {
    const socials = props.socials;
    return (
        <div className={styles.container}>
            <IconLogoStep4 isWhite/>
            <div>
                <StyledText
                    tag='h2'
                    textAlign='center'
                    variant={TextStyles.h2}
                >{props.title}</StyledText>
                <StyledText
                    tag='p'
                    textAlign='center'
                    variant={TextStyles.h3}
                    className={styles.subtitle}>
                    {props.subtitle}
                </StyledText>
            </div>
            <div className={styles.email}>
                <Button variant='outlined' color='secondary' href={`mailto:${props.email}`} target="_blank">
                    <StyledText variant={TextStyles.button}>
                        {props.email}
                    </StyledText>
                </Button>
            </div>
            <div>
                <StyledText
                    tag='p'
                    textAlign='center'
                    variant={TextStyles.h4}
                    className={styles.socialsTitle}
                >{props.socialTitle}</StyledText>
                <div className={styles.socialArrow}>
                    <IconArrowDown/>
                </div>
            </div>
            <SocialButtonList className={styles.socials} socials={socials}/>
        </div>
    )
}

export default FormSuccessBlock;
